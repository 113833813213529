import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Modal from '../Modal';
import { setTagName } from '../../redux/actions/tags';


const useStyles = makeStyles(() => ({
  input: {
    width: '100%',
  },
}));

export default function SimpleModal(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [name, setName] = useState('');

  const {
    tag, onClose,
  } = props;

  const spreadProps = { ...props };
  delete spreadProps.previousName;
  delete spreadProps.tagId;

  useEffect(() => {
    setName('');
    if (!tag || tag.default) return;
    setName(tag.name);
  }, [tag]);

  return (
    <Modal {...spreadProps}>
      <form className={classes.root} onSubmit={(e) => { e.preventDefault(); dispatch(setTagName(tag._id, name)); onClose(); }}>
        <Box mb={1}>
          <TextField
            fullWidth
            label="Namn"
            variant="outlined"
            value={name}
            onInput={(e) => setName(e.target.value)}
          />
        </Box>
        <Box>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
          >
            Spara
          </Button>
        </Box>
      </form>
    </Modal>
  );
}
