import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import jwtDecode from 'jwt-decode';
import { logout } from '../redux/actions/auth';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();

  let user = {};
  try {
    user = jwtDecode(token).user;
  } catch (e) {
    dispatch(logout());
  }

  return (
    <Route
      {...rest}
      // eslint-disable-next-line no-nested-ternary
      render={(props) => (!token ? (
        <Redirect to="/login" />
      )
        : <Component {...props} {...rest} />
      )}
    />
  );
};

export default PrivateRoute;
