/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import PersonIcon from '@material-ui/icons/Person';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import BuildOutlined from '@material-ui/icons/BuildOutlined';
import CloudOffOutlinedIcon from '@material-ui/icons/CloudOffOutlined';
import TagModalForm from '../Components/Tag/TagModalForm';
import { getReader } from '../redux/actions/readers';
import { logout } from '../redux/actions/auth';
import TagOverviewCard from '../Components/Tag/TagOverviewCard';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  menu: {
    background: '#222',
  },
  emptyResults: {
    textAlign: 'center',
    padding: theme.spacing(2, 0),
    color: theme.palette.text.primary,
  },
}));

const Reader = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const readers = useSelector((state) => state.readers);

  const [anchorEl, setAnchorEl] = useState(null);
  const [showTag, setShowTag] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isReaderActive = () => {
    const now = new Date().getTime();
    const lastUpdate = new Date(readers.current.updatedAt).getTime();
    const margin = (60 * 10 * 1000);
    return lastUpdate + margin > now;
  };

  useEffect(() => {
    dispatch(getReader(id));
    const intervalId = setInterval(() => {
      dispatch(getReader(id));
    }, 3000);
    return () => clearInterval(intervalId);
  }, [id, dispatch]);

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.menu}>
        <Toolbar>
          <Typography variant="h6" className={classes.title} />
          <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
            <PersonIcon />
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}><Button color="inherit" className={classes.logout} onClick={() => dispatch(logout())}>Logout</Button></MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Box px={2} py={4}>
        {!isReaderActive() ? (
          <Box className={classes.emptyResults}>
            <CloudOffOutlinedIcon fontSize="large" />
            <Typography variant="h5">Avläsaren är offline</Typography>
          </Box>
        ) : readers.current.tags.length > 0 ? (
          <Box>
            <Grid container spacing={2}>
              {readers.current.tags.map((tag) => (
                <Grid item xs={12} sm={6} key={tag._id}>
                  <TagOverviewCard tag={tag} onClick={() => setShowTag(tag)} />
                </Grid>
              ))}
            </Grid>
            <TagModalForm open={showTag !== null} onClose={() => setShowTag(null)} tag={showTag} />
          </Box>
        ) : (
          <Box className={classes.emptyResults}>
            <BuildOutlined fontSize="large" />
            <Typography variant="h5">Inga verktyg hittades</Typography>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default Reader;
