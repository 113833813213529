import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { login } from '../redux/actions/auth';


const useStyles = makeStyles(() => ({
  root: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerWrap: {
    width: '100vw',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  textfield: {
    maxWidth: '43px',
    '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none',
    },
  },
  confirmButton: {
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    width: '95%',
    marginTop: '1em',
  },
}));

const Confirm = () => {
  const classes = useStyles();
  const id = useSelector((state) => state.auth.id);
  const phone = useSelector((state) => state.auth.phone);
  const dispatch = useDispatch();

  const history = useHistory();

  const [code, setCode] = useState(['', '', '', '', '', '']);

  const confirm = () => {
    dispatch(login({ phone, id, code: code.join('') }, { history }));
  };

  const inputKeyDown = (e) => {
    const { form } = e.target;
    const formInputFields = Array.prototype.slice.call(form.elements).filter((element) => element.tagName === 'INPUT');
    const currentInputIndex = formInputFields.indexOf(e.target);
    const newCode = code;

    // keyboard 0-9
    if (e.keyCode >= 48 && e.keyCode <= 57) {
      newCode[currentInputIndex] = e.keyCode - 48;
      setCode([...newCode]);

      const nextInput = formInputFields[currentInputIndex + 1];
      if (nextInput) nextInput.focus();

    // keyboard backspace
    } else if (e.keyCode === 8) {
      newCode[currentInputIndex] = '';
      setCode([...newCode]);

      const prevInput = formInputFields[currentInputIndex - 1];
      if (prevInput) prevInput.focus();
    }

    if (e.keyCode !== 13) e.preventDefault();
  };

  useEffect(() => {
    if (id) return;

    history.push('/login');
  }, [id, history]);

  return (
    <div className={classes.root}>
      <form noValidate autoComplete="off" onSubmit={(e) => { e.preventDefault(); confirm(); }}>
        <div className={classes.innerWrap}>
          {code.map((value, index) => (
            <TextField
            // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={classes.textfield}
              id="outlined-basic"
              variant="outlined"
              type="number"
              autoFocus={index === 0}
              value={value}
              onKeyDown={(e) => inputKeyDown(e)}
              inputProps={{
                style: {
                  textAlign: 'center',
                  fontSize: '24px',
                  padding: '10px',
                },
              }}
            />
          ))}
        </div>
        <div className={classes.confirmButton}>
          <Button className={classes.button} size="large" variant="contained" color="primary" type="submit">
            Bekräfta
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Confirm;
