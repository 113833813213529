const initialState = {
  showError: false,
  code: '',
  message: '',
  field: '',
  type: '',
};

const errorReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'set-error':
      return {
        ...state,
        showError: true,
        code: action.payload.error.code,
        field: action.payload.error.field,
        type: action.payload.error.type,
        message: action.payload.error.message,
      };
    case 'reset-error':
      return { ...initialState };
    default:
      return state;
  }
};

export default errorReducer;
