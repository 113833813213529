const initialState = {
  list: [],
  current: {
    _id: '',
    name: '',
    tags: [],
    updatedAt: '',
  },
};

const readerReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'receive-reader':
      return { ...state, current: action.payload };
    default:
      return state;
  }
};

export default readerReducer;
