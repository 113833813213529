import { combineReducers } from 'redux';
import authReducer from './auth-reducer';
import errorReducer from './error-reducer';
import readersReducer from './readers-reducer';
import userReducer from './user-reducer';

export default combineReducers({
  auth: authReducer,
  error: errorReducer,
  readers: readersReducer,
  user: userReducer,
});
