const initialState = {
  user: {},
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'get-user':
      return { ...state, user: action.payload.user };
    case 'apply-name':
      return { ...state, token: action.payload.token };
    default:
      return state;
  }
};

export default userReducer;
