import api from '../../api';
import { setError } from './error';

export const receiveLoginCode = (phone, { history }) => async (dispatch) => {
  try {
    const { data } = await api.post('auth/receive-login-code', {
      phone,
    });
    dispatch({
      type: 'receive-login-code',
      payload: data,
    });
    history.push('/confirm');
  } catch (err) {
    dispatch(setError(err.response.data));
    console.error(err);
  }
};

export const savePhone = (phone, { history }) => async (dispatch) => {
  dispatch({
    type: 'save-phone',
    payload: {
      phone,
    },
  });
  dispatch(receiveLoginCode(phone, { history }));
};

export const login = ({ phone, id, code }, { history }) => async (dispatch) => {
  try {
    const { data } = await api.post('auth/login', {
      phone,
      id,
      code,
    });
    dispatch({
      type: 'login',
      payload: data,
    });
    history.push('/');
  } catch (err) {
    dispatch(setError(err.response.data));
    console.error(err);
  }
};

export const logout = () => async (dispatch) => {
  dispatch({ type: 'logout' });
};
