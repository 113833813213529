import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  paper: {
    width: '100%',
    padding: theme.spacing(3),
    outline: '0',
    boxSizing: 'border-box',
  },
}));

export default function SimpleModal(props) {
  const classes = useStyles();

  const { children } = props;

  return (

    <Modal
      {...props}
      className={classes.modal}
    >
      <Paper className={classes.paper}>
        {children}
      </Paper>
    </Modal>
  );
}
