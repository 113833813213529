import axios from 'axios';
import store from './redux/store';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

api.interceptors.request.use((config) => {
  const { auth } = store.getState();
  const newConfig = config;
  newConfig.headers.Authorization = auth.token;
  return newConfig;
});

export default api;
