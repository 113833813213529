
import React from 'react';
import clsx from 'clsx';

// MUI
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

import TagImage from './TagImage';


const useStyles = makeStyles((theme) => ({
  card: {
    padding: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    border: '1px transparent',
    '&.isDefault': {
      cursor: 'pointer',
      border: '1px dashed',
      boxShadow: 'none',
      background: 'transparent',
    },
  },
  content: {
    paddingLeft: '1rem',
    display: 'flex',
    alignItems: 'center',
  },
  tagName: {
    fontSize: '1.2rem',
  },
}));

export default function Toast(props) {
  const classes = useStyles();

  const {
    tag,
    onClick,
  } = props;

  const isDefault = tag.default;

  return (
    <Card className={clsx(classes.card, { isDefault })} onClick={onClick}>
      <TagImage isDefault={isDefault} />
      <div className={classes.content}>
        <span className={classes.tagName} key={tag._id}>{tag.name}</span>
      </div>
    </Card>
  );
}
