import React, { useEffect } from 'react';

// MUI
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';

import { resetError } from '../redux/actions/error';

const useStyles = makeStyles((theme) => ({
  toast: {
    flexWrap: 'nowrap',
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  icon: {
    fontSize: 20,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    fontSize: 14,
  },
}));

export default function Toast() {
  const classes = useStyles();

  const error = useSelector((state) => state.error);
  const dispatch = useDispatch();

  useEffect(() => {
    if (error.message) {
      // Autohide after 5 seconds
      setTimeout(() => {
        dispatch(resetError());
      }, 8000);
    }
  }, [error, dispatch]);


  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={error.showError}
    >
      <SnackbarContent
        className={`${classes.toast} ${classes.error}`}
        aria-describedby="client-snackbar"
        message={(
          <span id="client-snackbar" className={classes.message}>
            <ErrorIcon className={classes.icon} />
            {error.message}
          </span>
        )}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={() => dispatch(resetError())}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
}
