import React from 'react';
import clsx from 'clsx';

// MUI
import { makeStyles } from '@material-ui/core/styles';
import BuildIcon from '@material-ui/icons/Build';
import AddCircle from '@material-ui/icons/AddCircle';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    padding: '1rem',
    background: theme.palette.divider,
    borderRadius: '3px',
    '&.isDefault': {
      background: 'transparent',
    },
  },
}));

export default function TagImage(props) {
  const classes = useStyles();

  const { isDefault } = props;
  return (
    <div className={clsx(classes.container, { isDefault })}>
      {
        isDefault
          ? (<AddCircle />)
          : (<BuildIcon color="primary" />)
      }

    </div>
  );
}
