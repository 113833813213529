import api from '../../api';
import { setError } from './error';

export const setTagName = (id, name) => async (dispatch) => {
  try {
    api.put(`tags/${id}`, {
      name,
    });
  } catch (err) {
    console.error(err);
    dispatch(setError(err.response.data));
  }
};
