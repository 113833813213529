const initialState = {
  id: '',
  token: localStorage.getItem('token') || '',
  phone: '',
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'login':
      localStorage.setItem('token', action.payload.token);
      return { ...state, token: action.payload.token };
    case 'receive-login-code':
      return { ...state, id: action.payload.id };
    case 'save-phone':
      return { ...state, phone: action.payload.phone };
    case 'logout':
      localStorage.removeItem('token');
      return { ...initialState, token: '' };
    default:
      return state;
  }
};

export default authReducer;
