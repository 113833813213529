import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BuildIcon from '@material-ui/icons/Build';
import { Typography } from '@material-ui/core';
import { savePhone } from '../redux/actions/auth';


const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  textfield: {
    maxWidth: '400px',
    width: '80%',
  },
}));

const Login = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const token = useSelector((state) => state.auth.token);

  const [phoneInput, setPhoneInput] = useState('');

  useEffect(() => {
    if (!token) return;

    history.push('/');
  }, [token, history]);

  return (
    <form className={classes.root} onSubmit={(e) => { e.preventDefault(); dispatch(savePhone(phoneInput, { history })); }}>
      <TextField
        className={classes.textfield}
        id="outlined-basic"
        label="Telefonnummer"
        variant="outlined"
        type="tel"
        autoFocus
        onInput={(e) => setPhoneInput(e.target.value)}
        InputProps={{
          endAdornment: <IconButton color="primary" type="submit"><ArrowRightAltIcon /></IconButton>,
        }}
      />
    </form>
  );
};

export default Login;
