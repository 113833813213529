import React from 'react';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Router from './Router/Router';
import Toast from './Components/Toast';

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#ff6e04',
    },
  },
});

const useStyles = makeStyles(() => ({
  root: {
    background: theme.palette.background.default,
    minHeight: '100vh',
  },
}));

function App() {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Toast />
        <Router />
      </div>
    </ThemeProvider>
  );
}

export default App;
