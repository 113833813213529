export const setError = (error) => async (dispatch) => {
  dispatch({
    type: 'set-error',
    payload: {
      error,
    },
  });
};

export const resetError = () => async (dispatch) => {
  dispatch({ type: 'reset-error' });
};
