import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/';
import thunk from 'redux-thunk';
import reducer from './reducers';

const middleWare = [thunk];

const composeEnhancers = composeWithDevTools({ trace: true });

const store = createStore(
  reducer,
  compose(composeEnhancers(applyMiddleware(...middleWare))),
);

export default store;
