import api from '../../api';

export const getReader = (id) => async (dispatch) => {
  try {
    const { data } = await api.get(`readers/${id}`);
    dispatch({
      type: 'receive-reader',
      payload: data,
    });
  } catch (err) {
    console.error(err);
  }
};
