import React from 'react';
import {
  BrowserRouter, Route, Switch, Redirect,
} from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Login from '../Views/Login';
import Confirm from '../Views/Confirm';
import Reader from '../Views/Reader';

const Router = () => (
  <BrowserRouter>
    <Switch>
      <PrivateRoute exact path="/" component={Redirect} to="/readers/1716527015733018" />
      <Route exact path="/login" component={Login} />
      <Route exact path="/confirm" component={Confirm} />
      <PrivateRoute exact path="/readers/:id" component={Reader} />
    </Switch>
  </BrowserRouter>
);

export default Router;
